import Amplify, { Auth, Storage, API, a } from 'aws-amplify'
import './VR_AllPendingApplications.css'; // Tell webpack that Button.js uses these styles
import React, {Component, Fragment, useRef} from 'react';
import awsconfig from '../aws-exports.js';
import { navigationHandler, routeProps } from '../utilities/navigator.js';
import LoadingIcons from 'react-loading-icons'
import { tokenLifeTimeLimit, checkValidAuthTokenIntervalPeriod_ms,  LENDERBLOCKADMINWHITELIST} from '../blenderData/securityConfigurations';
import 'semantic-ui-css/semantic.min.css'
import { Button, Modal, Header, Image, Dropdown} from 'semantic-ui-react'
import { Link } from 'react-router-dom';


var AWS = require('aws-sdk');

Amplify.configure(awsconfig);
Auth.configure(awsconfig);

var checkValidAuthTokenInterval;

var cognitoID;
var currentUser;
var currentUserCap;
var stripeAccountID;
var stripeCustomerID;
var primaryDisplayCurrency;
var secondaryDisplayCurrency;
var publicKey;
var corporateBackedUsernamesArray;

var currentPendingApplicationsList;

let applicantUsername;

let that;

let duplicateApplicantCount
let skippedApplicantsCount
let completedActivatedApplicantCount

let monthLengtheningEnum = {
    JAN: 'January',
    FEB: 'February',
    MAR: 'March',
    APR: 'April',
    MAY: 'May',
    JUN: 'June',
    JUL: 'July',
    AUG: 'August',
    SEP: 'September',
    OCT: 'October',
    NOV: 'November',
    DEC: 'December',
}

let rand = () => Math.floor(Math.random() * 20) - 10;

let currentPendingApplicantUsernames = []

// const Backdrop = styled("div")`
//   position: fixed;
//   z-index: 1040;
//   top: 0;
//   bottom: 0;
//   left: 0;
//   right: 0;
//   background-color: #000;
//   opacity: 0.5;
// `;

// // we use some pseudo random coords so nested modals
// // don't sit right on top of each other.
// const RandomlyPositionedModal = styled(Modal)`
//   position: fixed;
//   width: 400px;
//   z-index: 1040;
//   top: ${() => 50 + rand()}%;
//   left: ${() => 50 + rand()}%;
//   border: 1px solid #e5e5e5;
//   background-color: white;
//   box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
//   padding: 20px;
// `;

const renderBackdrop = () => 
    <div style={{ 
        position: 'fixed',
        zIndex: 1040,
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        backgroundColor: '#000',
        opacity: 0.5
    }}/>

const sortOrder = {
    leastToGreatest: 'ltg',
    greatestToLeast: 'gtl',
}

function sortApplicantsByName(applicantArray, reverse){
    return applicantArray.sort((a, b) => {
        if(reverse) return (a.thisApplicantUsername < b.thisApplicantUsername) ? 1 : -1
        else return (a.thisApplicantUsername > b.thisApplicantUsername) ? 1 : -1
        
    })
}

function sortApplicantsByDate(applicantArray, reverse){
    return applicantArray.sort((a, b) => {
        if(reverse) return (a.lastInteractionTimestamp < b.lastInteractionTimestamp) ? 1 : -1
        else return (a.lastInteractionTimestamp > b.lastInteractionTimestamp) ? 1 : -1
    })
}

class AllPendingApplications extends Component {

    constructor() {
        super()

        this.state = {
            usernameText: '',
            passwordText: '',
            touched: false,
            navigationDestinationObject: {
                navigationRoute: '',
            },
            button_recentApp_opacity: 1,
            button_pendingApp_opacity: 1,
            pendingApplicationsList: [],
            filteredApplicationList: [],
            showLoaderOverlay: false,
            isListFiltered: false,
            sortDropdownValue: 'sort by',
            filterDropdownValue: 'filter by',
        };
        that = this
        currentPendingApplicationsList = []
    }

    checkValidAuthToken(){
        let navigationDestinationObject = {
          navigationRoute: ''
        };
        
        Auth.currentAuthenticatedUser().then((res) => {
            if(res){
    
                currentUser = res.username
        
                if(!LENDERBLOCKADMINWHITELIST.includes(String(currentUser).toLowerCase())){
                    Auth.signOut()
                    that.setState({
                        navigationDestinationObject: {
                            navigationRoute: '/login',
                        },
                    }) 
                }
            } else {
                Auth.signOut()
                that.setState({
                    navigationDestinationObject: {
                        navigationRoute: '/login',
                    },
                }) 
            }
        }).catch((err) => {
            Auth.signOut()
            that.setState({
                navigationDestinationObject: {
                    navigationRoute: '/login',
                },
            }) 
        })
        
        
        checkValidAuthTokenInterval = setInterval(() => {
          Auth.currentAuthenticatedUser().then((res) => {
            if(res){
              let authTokenIssueTime = Number(res.signInUserSession.idToken.payload.iat) * 1000
              let tokenLifetime = Date.now() - authTokenIssueTime
      
              if(tokenLifetime >= tokenLifeTimeLimit){
                that.setState({
                    navigationDestinationObject: {
                        navigationRoute: '/login'
                    }
                })
                if(navigationHandler(navigationDestinationObject)){
                  return navigationHandler(navigationDestinationObject)
                }
              }
            } else {
                that.setState({
                    navigationDestinationObject: {
                        navigationRoute: '/login'
                    }
                })
                if(navigationHandler(navigationDestinationObject)){
                    return navigationHandler(navigationDestinationObject)
                }
            }
          }).catch(() => {
            that.setState({
                navigationDestinationObject: {
                    navigationRoute: '/login'
                }
            })
            if(navigationHandler(navigationDestinationObject)){
              return navigationHandler(navigationDestinationObject)
            }
          })
        }, checkValidAuthTokenIntervalPeriod_ms)
    }

    componentWillUnmount(){

    }
    componentDidMount(){
        that.checkValidAuthToken()

        that.initialMountComponents()

        applicantUsername = localStorage.getItem('applicantUsername')

            currentPendingApplicationsList = []
            currentPendingApplicantUsernames = []
             
            this.setState({
                usernameText: '',
                passwordText: '',
                touched: false,
                navigationDestinationObject: {
                    navigationRoute: '',
                },
                button_recentApp_opacity: 1,
                button_pendingApp_opacity: 1,
                pendingApplicationsList: [],
                showLoaderOverlay: true,
            })
            
            Auth.currentAuthenticatedUser().then((res) => {
                if(res){
                    let authTokenIssueTime = Number(res.signInUserSession.idToken.payload.iat) * 1000
                    let tokenLifetime = Date.now() - authTokenIssueTime
                    if(tokenLifetime < tokenLifeTimeLimit){
                        currentUser = res.username
                        cognitoID = res.signInUserSession.idToken.jwtToken
                                        
                        currentUserCap = currentUser.toUpperCase()
                        if(currentUserCap.length > 10){
                            currentUserCap =  currentUserCap.substr(0, 9).concat(' ...')
                        }
                        
                        that.setState({
                            currentUser,
                            navigationDestinationObject: {
                                navigationRoute: '',
                            },
                        })

                        that.initialMountComponents()

                    } else {
                        that.setState({
                            navigationDestinationObject: {
                                navigationRoute: '/login',
                            },
                        })
                    }
                } else {
                    that.setState({
                        navigationDestinationObject: {
                            navigationRoute: '/login',
                        },
                    })
                }
            
            }).catch((err) => {
                that.setState({
                    navigationDestinationObject: {
                        navigationRoute: '/login',
                    },
                })
            })
    }

    toggleTouched = () => {
        this.setState( prevState => ({
        touched: !prevState.touched
        }));
    }
    
    handleMouseUp = () => {
        // Handle smooth animation when clicking without holding
        setTimeout( () => {
        this.setState({ touched: false });
        }, 150);
    }


    finishForLoopAndConcat(thisApplicantUsername, messageLineArray, lastDocumentSubmissionTimestamp, pendingDocumentCount, isLastApplicationBool, pendingApplicantsCt){

        let lastMessageSenderUsername = messageLineArray[messageLineArray.length-1].senderUsername
        let lastMessageTimestamp = Date.parse(messageLineArray[messageLineArray.length-1].timestamp)
        let newMessageBool = false

        if(lastMessageSenderUsername.toLowerCase() == thisApplicantUsername && lastMessageTimestamp > 0){
            newMessageBool = true
        }

        let lastInteractionTimestamp
        if(lastMessageTimestamp > lastDocumentSubmissionTimestamp){
            lastInteractionTimestamp = lastMessageTimestamp
        } else {
            lastInteractionTimestamp = lastDocumentSubmissionTimestamp
        }

        let lastInteractionTimestampFormatted = String(new Date(lastInteractionTimestamp))
        lastInteractionTimestampFormatted = monthLengtheningEnum[String(lastInteractionTimestampFormatted.substring(4,7)).toUpperCase()] + String(lastInteractionTimestampFormatted.substring(7,10)) + ',' + String(lastInteractionTimestampFormatted.substring(10,15))

        let newApplicationItem = {
            thisApplicantUsername,
            pendingDocumentCount,
            newMessageBool,
            lastInteractionTimestamp,
            lastInteractionTimestampFormatted,
            key: String(lastInteractionTimestamp)
        }


        if((Number(pendingDocumentCount) > 0 || newMessageBool) && lastInteractionTimestamp > 0){

            if(!currentPendingApplicantUsernames.includes(thisApplicantUsername)){
            
                currentPendingApplicationsList.push(newApplicationItem)
                currentPendingApplicationsList.sort(function(a,b) {
                    return (b.lastInteractionTimestamp - a.lastInteractionTimestamp)
                });
                currentPendingApplicantUsernames.push(thisApplicantUsername)  
            }

            that.setState({
                pendingApplicationsList: currentPendingApplicationsList,
                // showLoaderOverlay: false,
            })

          
        } else {
            skippedApplicantsCount += 1 

            that.setState({
                pendingApplicationsList: currentPendingApplicationsList,
                // showLoaderOverlay: false,
            })
            
        }
        this.setState({
            filteredApplicationList: that.state.pendingApplicationsList.filter((item) => {
                return item.lastInteractionTimestamp < (Date.now() - 30 * 24 * 60 * 60 * 1000)
            })
        })
    }


    initialMountComponents(){

        Auth.currentAuthenticatedUser().then((res) => {
            currentUser = res.username
            cognitoID = res.signInUserSession.idToken.jwtToken

            currentUserCap = currentUser.toUpperCase()
            if(currentUserCap.length > 10){
                currentUserCap =  currentUserCap.substr(0, 9).concat(' ...')
            }
            that.setState({ currentUser })
            
            API.put('api1169ba1e', '', { 
                headers: { Authorization: `Bearer ${cognitoID}` },
                body: { 
                category: 'db',
                tableName: 'users', 
                primaryKey: String(currentUser), 
                requestOperation: 'get',
                }
            }).then((userData) => {
                
                primaryDisplayCurrency = userData.Item.preferences.primaryDisplayCurrency
                secondaryDisplayCurrency = userData.Item.preferences.secondaryDisplayCurrency
                publicKey = userData.Item.publicKey


                stripeAccountID = userData.Item.stripeAccountID
                stripeCustomerID = userData.Item.stripeCustomerID


                API.put('api1169ba1e', '', { 
                    headers: { Authorization: `Bearer ${cognitoID}` },
                    body: { 
                        category: 'db',
                        tableName: 'general', 
                        primaryKey: 'orderedPendingVerifiedRegistryApplicants', 
                        requestOperation: 'get',
                    }
                }).then((pendingVerifiedRegistryApplicants) => {
                    let orderedPendingVerifiedRegistryApplicants = pendingVerifiedRegistryApplicants.Item.orderedPendingVerifiedRegistryApplicants

                    // that.removeEarlierDuplicatesFromArray(orderedPendingVerifiedRegistryApplicants)

                    duplicateApplicantCount = 0
                    skippedApplicantsCount = 0
                    completedActivatedApplicantCount = 0

                    let alreadyLoopedApplicantArray = []

                    if(orderedPendingVerifiedRegistryApplicants.length != 0){

                        loopOrderedPendingVerifiedRegistryApplicants(0)
                        function loopOrderedPendingVerifiedRegistryApplicants(v){
                            if(v<=orderedPendingVerifiedRegistryApplicants.length-1){
                                let thisApplicantUsername = orderedPendingVerifiedRegistryApplicants[v]
                                if(alreadyLoopedApplicantArray.includes(thisApplicantUsername)){
                                    duplicateApplicantCount += 1
                                } else {
                                    alreadyLoopedApplicantArray.push(thisApplicantUsername)
                                }

                                let isLastApplicationBool = false
                                if(v == orderedPendingVerifiedRegistryApplicants.length-1){
                                    isLastApplicationBool = true
                                }

                                API.put('api1169ba1e', '', { 
                                    headers: { Authorization: `Bearer ${cognitoID}` },
                                    body: { 
                                        category: 'db',
                                        tableName: 'verifiedRegistryApplications', 
                                        primaryKey: thisApplicantUsername, 
                                        requestOperation: 'get',
                                    }
                                }).then((thisApplication) => {

                                    let applicationStatus = thisApplication.Item.applicationStatus
                                    let messageLineArray = thisApplication.Item.messageLineArray
                                    let requestedAwaitingDocumentKeyArray = thisApplication.Item.requestedAwaitingDocumentKeyArray
                                    let requestedAwaitingDocumentKeyObjects = thisApplication.Item.requestedAwaitingDocumentKeyObjects
                                    let notes = thisApplication.Item.notes
                                    let allDocumentFileIDs = thisApplication.Item.allDocumentFileIDs
                                    let adminReviewUpdates = thisApplication.Item.adminReviewUpdates
                                    let lastReviewedDocumentID = adminReviewUpdates.lastViewedDocumentID

                                    let pendingDocumentCount = 0
                                    let lastDocumentSubmissionTimestamp = 0


                                    if(String(applicationStatus).toLowerCase() != 'activated'){
                                    
    
                                        
                                        let latestMessageTimestamp = 0;

                                        if(messageLineArray.length > 0){
                                        
                                            let thisMessageTimestamp = messageLineArray[messageLineArray.length-1].timestamp

                                            let day = thisMessageTimestamp.substring(8,10)
                                            let month = thisMessageTimestamp.substring(4,7)
                                            let year = thisMessageTimestamp.substring(11,15)
                                            let monthsEnum = {'Jan': "01", 'Feb': "02", 'Mar': "03", 'Apr': "04", "May": "05", 'Jun': "06", 'Jul': "07", 'Aug': "08", 'Sep': "09", 'Oct': "10", 'Nov': "11", 'Dec': "12"}
                                            month = monthsEnum[month]
                                            let thisMessageTimestampEdited = `${year}-${month}-${day}`

                                            latestMessageTimestamp = new Date(thisMessageTimestampEdited).getTime()

                                        }
                                        
                                        if(allDocumentFileIDs.length == 0){
                                            that.finishForLoopAndConcat(thisApplicantUsername, messageLineArray, latestMessageTimestamp, pendingDocumentCount, isLastApplicationBool, orderedPendingVerifiedRegistryApplicants.length-duplicateApplicantCount)
                                            loopOrderedPendingVerifiedRegistryApplicants(v+1)
                                        }
                                        for(let d=allDocumentFileIDs.length-1; d>=0; d--){

                                            let thisDocumentFileID = allDocumentFileIDs[d]
                                            lastDocumentSubmissionTimestamp = Number(String(thisDocumentFileID).substring(0,13))
                                
                                            if(Number(String(thisDocumentFileID).substring(0,13)) > Number(String(lastReviewedDocumentID).substring(0,13))){
                                                pendingDocumentCount += 1
                                                if(d == 0){
                                                    that.finishForLoopAndConcat(thisApplicantUsername, messageLineArray, lastDocumentSubmissionTimestamp > latestMessageTimestamp ? lastDocumentSubmissionTimestamp : latestMessageTimestamp, pendingDocumentCount, isLastApplicationBool, orderedPendingVerifiedRegistryApplicants.length-duplicateApplicantCount)   
                                                    loopOrderedPendingVerifiedRegistryApplicants(v+1)
                                                }
                                            } else {
                                                that.finishForLoopAndConcat(thisApplicantUsername, messageLineArray, lastDocumentSubmissionTimestamp > latestMessageTimestamp ? lastDocumentSubmissionTimestamp : latestMessageTimestamp, pendingDocumentCount, isLastApplicationBool, orderedPendingVerifiedRegistryApplicants.length-duplicateApplicantCount)
                                                loopOrderedPendingVerifiedRegistryApplicants(v+1)
                                            }
                                        }



                                    } else {

                                        completedActivatedApplicantCount += 1
                                        that.finishForLoopAndConcat(thisApplicantUsername, messageLineArray, lastDocumentSubmissionTimestamp, pendingDocumentCount, isLastApplicationBool, orderedPendingVerifiedRegistryApplicants.length-duplicateApplicantCount)
                                        loopOrderedPendingVerifiedRegistryApplicants(v+1)
                                    }

                                })    
                            } else {
                                that.setState({
                                    showLoaderOverlay: false,
                                })
                            }
                        }
                        
                    } else {
                        that.setState({
                            showLoaderOverlay: false,
                        })
                    }
                })    
            })
        });
    }

    render(){
        
        const { touched } = this.state;
        const className = touched ? 'button1_touched' : 'button1';

        applicantUsername = localStorage.getItem('applicantUsername')

        if(navigationHandler(that.state.navigationDestinationObject)){
            return navigationHandler(that.state.navigationDestinationObject)
        }

        

        return (
        <div className="AllPendingApplications">

            <React.Fragment>
                {
                    that.state.showLoaderOverlay
                    ?
                    <div
                        style={{
                            position: 'absolute',
                            // top: '2vh',
                            // right: '2vw',
                            // left: '2vw',
                            width: '100vw',
                            height: '100vh',
                            justifyContent: 'center',
                            alignItems: 'center',
                            backgroundColor: 'rgba(255, 255, 255, 0.25)',
                            padding: 10,
                            zIndex: 2000,
                        }}
                    >
                        <LoadingIcons.BallTriangle 
                            style={{
                                marginTop: '45vh',
                                marginBottom: '45vh',
                                marginRight: '42vw',
                                marginLeft: '42vw',
                            }} 
                            strokeWidth={1}
                            stroke="rgba(255, 255, 255, 1)" 
                            fill="rgba(0, 158, 129, 1)" 
                             
                        />
                    </div>
                    :
                    null
                }
            </React.Fragment>



            <div className="headerRowBox"> 
                <div 
                    onClick={() => {
                      that.setState({
                        navigationDestinationObject: {
                            navigationRoute: '/home',
                        }
                      }) 
                    }}
                >
                    <div className="logoBox"> 
                        <img className="logoImage" src="https://lenderblockpublicicons.s3.amazonaws.com/TBPLogoSquare.png"></img>
                        <p className="logoText"><b>THE BLENDER PLATFORM</b></p>
                    </div>
                </div>
                <div className="headerRowBoxNavBar">
                    
                  
                </div>
                <div className="headerUsernameBox"> 
                    <p style={{ textAlign: 'right', marginTop: 12.5, }} className="logoText"><b>
                        {/* <span style={{ textAlign: 'right', color: 'rgba(28, 28, 54, 1)' }}>
                            {'User Admin\n'}
                        </span> */}
                        {String(currentUser).toUpperCase()}
                    </b></p>
                    <Button
                        className="buttonStyle"
                        style={{
                        padding: 10,
                        marginLeft: 25,
                        }} 
                        onMouseDown={this.toggleTouched} 
                        onMouseUp={this.handleMouseUp} 
                        onClick={() => { 
                            Auth.signOut()
                            that.setState({
                                navigationDestinationObject: {
                                    navigationRoute: '/login',
                                }
                            }) 
                        }}
                    >
                        Sign Out
                    </Button>
                </div>
            </div>
            <div 
                style={{
                    backgroundColor:'#282c34',
                    width: '100%',
                    height: '100vh',
                    paddingBottom: 20,
                    display: 'flex',
                    flexDirection: 'column',
                    /* align-items: center;
                    justify-content: center; */
                }}
            >
                <p 
                    style={{ 
                        position: 'absolute', 
                        top: 135, 
                        left: 25, 
                        color: 'rgba(0, 158, 129, 1)', 
                        fontSize: 18, 
                        fontWeight: '600', 
                        textAlign: 'left',
                        textShadowColor: 'rgba(255, 255, 255, 1)',
                        textShadowOffset: {width: 10, height: 10},
                        textShadowRadius: 10,  
                        textUnderlineOffset: 10, 
                        textDecorationLine: 'underline', 
                        textDecorationColor: 'rgba(0, 158, 129, 1)'
                    }}
                >
                    Administrator Center
                    <span 
                        style={{ 
                            textAlign: 'left', 
                            fontSize: 16, 
                            fontWeight: '500', 
                            color: 'rgba(255, 255, 255, 1)', 
                        }}
                    >
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{'Verified Registry Management'}
                    </span>
                </p>
                <Button
                    className="buttonStyle"
                    style={{
                    marginLeft: 25,
                    position: 'absolute', 
                    top: 205, 
                    left: 0, 
                    }} 
                    onMouseDown={this.toggleTouched} 
                    onMouseUp={this.handleMouseUp} 
                    onClick={() => {
                        that.setState({
                            navigationDestinationObject: {
                                navigationRoute: '/home',
                            }
                        }) 
                    }}
                >
                    {'<<'} &nbsp; {'Administrator Center Home'}
                </Button>
                    
                <div style={{display: 'flex', flexDirection: 'column', height: '100%', width: '100%' , paddingTop:235, paddingLeft:25}}>
                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', height:30, width: '45vw' }}>
                        <p 
                            style={{ 
                                // position: 'absolute', 
                                marginLeft: 0, 
                                color: 'rgba(255, 255, 255, 1)', 
                                fontSize: 17, 
                                fontWeight: '600', 
                                textAlign: 'left',
                                textShadowColor: 'rgba(255, 255, 255, 1)',
                                textShadowOffset: {width: 10, height: 10},
                                textShadowRadius: 10,  
                            }}
                        >
                            {`All Pending Applicants (${that.state.pendingApplicationsList.length})`}
                        </p>
                        <div style={{display: 'flex', flexDirection: 'row', gap: '10px'}}>
                            <Dropdown text={this.state.sortDropdownValue} 
                            className='dropdownStyle'
                            style={{
                                margin: 'auto',
                                borderRadius: 3,
                                borderColor: 'rgba(255, 255, 255, 1)',
                                borderStyle: 'solid',
                                borderWidth: 0.5,
                                justifyContent: 'center',
                                alignItems: 'center',
                                fontSize: 11, 
                                fontWeight: '600',
                                paddingLeft: 5,
                                paddingRight: 5,
                                }} >
                                <Dropdown.Menu>
                                    <Dropdown.Item text='A-Z' onClick={()=>{
                                        this.setState({
                                            filteredApplicationList: sortApplicantsByName(this.state.isListFiltered ? this.state.filteredApplicationList : that.state.pendingApplicationsList, false),
                                            sortDropdownValue: 'A-Z'
                                        })
                                    }}/>
                                    <Dropdown.Item text='Z-A' onClick={()=>{
                                        this.setState({
                                            filteredApplicationList: sortApplicantsByName(this.state.isListFiltered ? this.state.filteredApplicationList : that.state.pendingApplicationsList, true),
                                            sortDropdownValue: 'Z-A'
                                        })
                                    }}/>
                                    <Dropdown.Item text='recent' onClick={()=>{
                                        this.setState({
                                            isListFiltered: true,filteredApplicationList: sortApplicantsByDate(this.state.isListFiltered ? this.state.filteredApplicationList : that.state.pendingApplicationsList, true),
                                            sortDropdownValue: 'recent'
                                        })
                                    }}/>
                                    <Dropdown.Item text='oldest' onClick={()=>{
                                        this.setState({
                                            isListFiltered: true,filteredApplicationList: sortApplicantsByDate(this.state.isListFiltered ? this.state.filteredApplicationList : that.state.pendingApplicationsListt, false),
                                            sortDropdownValue: 'oldest'
                                        })
                                    }}/>
                                </Dropdown.Menu>
                            </Dropdown>
                            <Dropdown text={this.state.filterDropdownValue} 
                                className='dropdownStyle'
                                style={{
                                margin: 'auto',
                                //backgroundColor: 'rgba(204, 12, 12, 0)',
                                borderRadius: 3,
                                //borderColor: 'rgba(255, 255, 255, 1)',
                                borderStyle: 'solid',
                                borderWidth: 0.5,
                                justifyContent: 'center',
                                alignItems: 'center',
                                fontSize: 11, 
                                fontWeight: '600',
                                //color: 'rgba(255, 255, 255, 1)',
                                paddingLeft: 5,
                                paddingRight: 5,
                                }}>
                                <Dropdown.Menu >
                                    <Dropdown.Item text='pending documents' onClick={()=>{
                                        that.setState({
                                            isListFiltered: true,
                                            filteredApplicationList: that.state.pendingApplicationsList.filter((item) => {
                                                return item.pendingDocumentCount > 0
                                            }),
                                            filterDropdownValue: 'pending documents',
                                            sortDropdownValue: 'sort by'
                                        })
                                    }}/>
                                    <Dropdown.Item text='new messages' onClick={()=>{
                                        that.setState({
                                            isListFiltered: true,
                                            filteredApplicationList: that.state.pendingApplicationsList.filter((item) => {
                                                return item.newMessageBool
                                            }),
                                            filterDropdownValue: 'new messages',
                                            sortDropdownValue: 'sort by'
                                        })
                                    }}/>
                                    <Dropdown.Item text='dormant' onClick={()=>{
                                        that.setState({
                                            isListFiltered: true,
                                            filteredApplicationList: that.state.pendingApplicationsList.filter((item) => {
                                                return item.lastInteractionTimestamp < (Date.now() - 30 * 24 * 60 * 60 * 1000)
                                            }),
                                            filterDropdownValue: 'dormant',
                                            sortDropdownValue: 'sort by'
                                        })
                                    }}/>
                                    <Dropdown.Item text='all' onClick={()=>{
                                        that.setState({
                                            filteredApplicationList: that.state.pendingApplicationsList,
                                            filterDropdownValue: 'all',
                                            sortDropdownValue: 'sort by'
                                        })
                                    }}/>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                        </div>
                    <ul
                        style={{
                            listStyleType: 'none',
                            padding: 0, 
                            left: 25, 
                            width: '45vw',
                            // height: 500,
                            // borderStyle: 'solid',
                            borderRadius: 3,
                            backgroundColor: 'rgba(0, 158, 129, 0)',
                            borderColor: 'rgba(0, 158, 129, 1)',
                            justifyContent: 'center',
                            alignItems: 'center',
                            
                        }}
                    >
                        
                        {(that.state.filteredApplicationList).map(listitem => (

                            <li
                                key={listitem.key}
                                style={{
                                    // width: '45vw',
                                    // height: 150,
                                    borderRadius: 3,
                                    borderWidth: 0.5,
                                    borderStyle: 'solid',
                                    backgroundColor: 'rgba(0, 158, 129, 0.1)',
                                    borderColor: 'rgba(0, 158, 129, 1)',
                                    // justifyContent: 'center',
                                    // alignItems: 'center',
                                    //paddingLeft: 5, 
                                    marginTop: 20,
                                }}
                                onClick={() => {
                                    that.setState({
                                        navigationDestinationObject: {
                                           routeProps: {
                                                applicantUsername: listitem.thisApplicantUsername.toLowerCase()
                                             },
                                        }
                                    })
                                }}
                            >

                            <Button 
                                className="green-clear"
                                style={{
                                    width: '100%',
                                }}
                                href={`/vr_appshow/${listitem.thisApplicantUsername.toLowerCase()}`}>
                                <div 
                                    // to={{
                                    //     pathname: '/vr_appshow',
                                    //     state: {
                                    //         applicantUsername: listitem.thisApplicantUsername.toLowerCase()
                                    //     }
                                    // }}
                                >
                                    <p 
                                        style={{ 
                                            textAlign: 'left',
                                            fontSize: 14, 
                                            fontWeight: '500', 
                                            color: 'rgba(255, 255, 255, 1)', 
                                            marginTop: 10,
                                            marginLeft: 5,
                                        }}
                                    ><b>
                                        {`${listitem.thisApplicantUsername.toUpperCase()}`}
                                    </b></p>
                                    <p 
                                        style={{ 
                                            textAlign: 'left',
                                            fontSize: 14, 
                                            fontWeight: '500', 
                                            color: 'rgba(255, 255, 255, 1)', 
                                            marginTop: 15,
                                            marginLeft: 5,
                                            // textUnderlineOffset: 3, 
                                            // textDecorationLine: 'underline', 
                                            // textDecorationColor: 'rgba(255, 255, 255, 1)',
                                        }}
                                    >
                                        {'Updates:'}
                                    </p>
                                    <p 
                                        style={{ 
                                            textAlign: 'left',
                                            fontSize: 12, 
                                            fontWeight: '500', 
                                            color: 'rgba(255, 255, 255, 1)', 
                                            marginLeft: 25,
                                            // textUnderlineOffset: 3, 
                                            // textDecorationLine: 'underline', 
                                            // textDecorationColor: 'rgba(255, 255, 255, 1)',
                                        }}
                                    >
                                        {'New Document Submissions: '} {`${listitem.pendingDocumentCount}`}
                                    </p>
                                    <p 
                                        style={{ 
                                            textAlign: 'left',
                                            color: 'rgba(204, 12, 12, 0.8)',
                                            fontSize: 12, 
                                            fontWeight: '500', 
                                            marginLeft: 25,
                                            // textUnderlineOffset: 3, 
                                            // textDecorationLine: 'underline', 
                                            // textDecorationColor: 'rgba(255, 255, 255, 1)',
                                        }}
                                    >
                                        {`${listitem.newMessageBool ? 'New Applicant Message!' : ''}`}
                                    </p>
                                    <p 
                                        style={{ 
                                            textAlign: 'right',
                                            fontSize: 9,
                                            color: 'rgba(255, 255, 255, 1)',
                                            fontWeight: '500', 
                                            color: 'rgba(255, 255, 255, 1)', 
                                            marginBottom: 5,
                                            marginRight: 5,
                                        
                                        }}
                                    >
                                        {`Last Updated: ${listitem.lastInteractionTimestampFormatted}`}
                                    </p>
                                </div>
                                </Button>
                            </li>
                        ))}
                    </ul>
                </div>

            </div>
        
        </div>
        );
    }

}

export default AllPendingApplications;
