
import Amplify, { Auth, Storage, API } from 'aws-amplify'
import './Home.css'; // Tell webpack that Button.js uses these styles
import React, {Component, Fragment, useRef} from 'react';
import awsconfig from '../aws-exports.js';
import { navigationHandler, routeProps } from '../utilities/navigator.js';
import LoadingIcons from 'react-loading-icons'
import { tokenLifeTimeLimit, checkValidAuthTokenIntervalPeriod_ms,  LENDERBLOCKADMINWHITELIST} from '../blenderData/securityConfigurations';

import 'semantic-ui-css/semantic.min.css'
import { Button, Modal, Header, Image, } from 'semantic-ui-react'

var AWS = require('aws-sdk');

var checkValidAuthTokenInterval;

Amplify.configure(awsconfig);
Auth.configure(awsconfig);

var cognitoID;
var currentUser;
var currentUserCap;
var stripeAccountID;
var stripeCustomerID;
var primaryDisplayCurrency;
var secondaryDisplayCurrency;
var publicKey;
var corporateBackedUsernamesArray;
let proposeGasPrice;

var campaign;
let client;
const secretName = "PrivateBlenderKeys"
const region = 'us-east-1'

let applicantUsername;

let that;

let monthLengtheningEnum = {
    JAN: 'January',
    FEB: 'February',
    MAR: 'March',
    APR: 'April',
    MAY: 'May',
    JUN: 'June',
    JUL: 'July',
    AUG: 'August',
    SEP: 'September',
    OCT: 'October',
    NOV: 'November',
    DEC: 'December',
}

let rand = () => Math.floor(Math.random() * 20) - 10;

let currentPendingApplicantUsernames = []

// const Backdrop = styled("div")`
//   position: fixed;
//   z-index: 1040;
//   top: 0;
//   bottom: 0;
//   left: 0;
//   right: 0;
//   background-color: #000;
//   opacity: 0.5;
// `;

// // we use some pseudo random coords so nested modals
// // don't sit right on top of each other.
// const RandomlyPositionedModal = styled(Modal)`
//   position: fixed;
//   width: 400px;
//   z-index: 1040;
//   top: ${() => 50 + rand()}%;
//   left: ${() => 50 + rand()}%;
//   border: 1px solid #e5e5e5;
//   background-color: white;
//   box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
//   padding: 20px;
// `;

const renderBackdrop = () => 
    <div style={{ 
        position: 'fixed',
        zIndex: 1040,
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        backgroundColor: '#000',
        opacity: 0.5
    }}/>

class Home extends Component {

    constructor() {
        super()

        this.state = {
            usernameText: '',
            passwordText: '',
            touched: false,
            navigationDestinationObject: {
                navigationRoute: '',
            },
            button_recentApp_opacity: 1,
            button_pendingApp_opacity: 1,
            showLoaderOverlay: false,
        };
        that = this
    }

    checkValidAuthToken(){
        let navigationDestinationObject = {
          navigationRoute: ''
        };

        Auth.currentAuthenticatedUser().then((res) => {
            if(res){
    
                currentUser = res.username
        
                if(!LENDERBLOCKADMINWHITELIST.includes(String(currentUser).toLowerCase())){
                    Auth.signOut()
                    that.setState({
                        navigationDestinationObject: {
                            navigationRoute: '/login',
                        },
                    }) 
                }
            } else {
                Auth.signOut()
                that.setState({
                    navigationDestinationObject: {
                        navigationRoute: '/login',
                    },
                }) 
            }
        }).catch((err) => {
            Auth.signOut()
            that.setState({
                navigationDestinationObject: {
                    navigationRoute: '/login',
                },
            }) 
        })

        checkValidAuthTokenInterval = setInterval(() => {
          Auth.currentAuthenticatedUser().then((res) => {
            if(res){
              let authTokenIssueTime = Number(res.signInUserSession.idToken.payload.iat) * 1000
              let tokenLifetime = Date.now() - authTokenIssueTime
      
              if(tokenLifetime >= tokenLifeTimeLimit){
                that.setState({
                    navigationDestinationObject: {
                        navigationRoute: '/login'
                    }
                })
                if(navigationHandler(navigationDestinationObject)){
                  return navigationHandler(navigationDestinationObject)
                }
              }
            } else {
                that.setState({
                    navigationDestinationObject: {
                        navigationRoute: '/login'
                    }
                })
                if(navigationHandler(navigationDestinationObject)){
                    return navigationHandler(navigationDestinationObject)
                }
            }
          }).catch(() => {
            that.setState({
                navigationDestinationObject: {
                    navigationRoute: '/login'
                }
            })
            if(navigationHandler(navigationDestinationObject)){
              return navigationHandler(navigationDestinationObject)
            }
          })
        }, checkValidAuthTokenIntervalPeriod_ms)
    }

    componentWillUnmount(){

    }
    componentDidMount(){
        that.checkValidAuthToken()

        applicantUsername = localStorage.getItem('applicantUsername')

            this.setState({
                usernameText: '',
                passwordText: '',
                touched: false,
                navigationDestinationObject: {
                    navigationRoute: '',
                },
                button_recentApp_opacity: 1,
                button_pendingApp_opacity: 1,
            })
            
            Auth.currentAuthenticatedUser().then((res) => {
                if(res){
                    let authTokenIssueTime = Number(res.signInUserSession.idToken.payload.iat) * 1000
                    let tokenLifetime = Date.now() - authTokenIssueTime

                    if(tokenLifetime < tokenLifeTimeLimit){
                        currentUser = res.username
                        cognitoID = res.signInUserSession.idToken.jwtToken
                                        
                        currentUserCap = currentUser.toUpperCase()
                        if(currentUserCap.length > 10){
                            currentUserCap =  currentUserCap.substr(0, 9).concat(' ...')
                        }
                        
                        that.setState({
                            currentUser,
                            navigationDestinationObject: {
                                navigationRoute: '',
                            },
                        })
                    } else {
                        that.setState({
                            navigationDestinationObject: {
                                navigationRoute: '/login',
                            },
                        })
                    }
                } else {
                    that.setState({
                        navigationDestinationObject: {
                            navigationRoute: '/login',
                        },
                    })
                }
            
            }).catch((err) => {
                that.setState({
                    navigationDestinationObject: {
                        navigationRoute: '/login',
                    },
                })
            })
    }

    toggleTouched = () => {
        this.setState( prevState => ({
        touched: !prevState.touched
        }));
    }
    
    handleMouseUp = () => {
        // Handle smooth animation when clicking without holding
        setTimeout( () => {
        this.setState({ touched: false });
        }, 150);
    }

    render(){
        
        const { touched } = this.state;
        const className = touched ? 'button1_touched' : 'button1';

        applicantUsername = localStorage.getItem('applicantUsername')

        if(navigationHandler(that.state.navigationDestinationObject)){
            return navigationHandler(that.state.navigationDestinationObject)
        }

        return (
        <div className="Home">

            <React.Fragment>
                {
                    that.state.showLoaderOverlay
                    ?
                    <div
                        style={{
                            position: 'absolute',
                            // top: '2vh',
                            // right: '2vw',
                            // left: '2vw',
                            width: '100vw',
                            height: '100vh',
                            justifyContent: 'center',
                            alignItems: 'center',
                            backgroundColor: 'rgba(255, 255, 255, 0.25)',
                            padding: 10,
                            zIndex: 2000,
                        }}
                    >
                        <LoadingIcons.BallTriangle 
                            style={{
                                marginTop: '45vh',
                                marginBottom: '45vh',
                                marginRight: '42vw',
                                marginLeft: '42vw',
                            }} 
                            strokeWidth={1}
                            stroke="rgba(255, 255, 255, 1)" 
                            fill="rgba(0, 158, 129, 1)" 
                             
                        />
                    </div>
                    :
                    null
                }
            </React.Fragment>



            <div className="headerRowBox"> 
                <div>
                    <div className="logoBox"> 
                        <img className="logoImage" src="https://lenderblockpublicicons.s3.amazonaws.com/TBPLogoSquare.png"></img>
                        <p className="logoText"><b>THE BLENDER PLATFORM</b></p>
                    </div>
                </div>
                <div className="headerRowBoxNavBar">
                    
                  
                </div>
                <div className="headerUsernameBox"> 
                    <p style={{ textAlign: 'right', marginTop: 12.5, }} className="logoText"><b>
                        {/* <span style={{ textAlign: 'right', color: 'rgba(28, 28, 54, 1)' }}>
                            {'User Admin\n'}
                        </span> */}
                        {String(currentUser).toUpperCase()}
                    </b></p>
                    <Button
                        style={{
                        // height: 100,
                        padding: 10,
                        backgroundColor: 'rgba(204, 12, 12, 0)',
                        borderRadius: 3,
                        borderColor: 'rgba(255, 255, 255, 1)',
                        borderStyle: 'solid',
                        borderWidth: 0.5,
                        marginLeft: 25,
                        justifyContent: 'center',
                        alignItems: 'center',
                        fontSize: 11, 
                        fontWeight: '600',
                        color: 'rgba(255, 255, 255, 1)',
                        }} 
                        onMouseDown={this.toggleTouched} 
                        onMouseUp={this.handleMouseUp} 
                        onClick={() => { 
                            Auth.signOut()
                            that.setState({
                                navigationDestinationObject: {
                                    navigationRoute: '/login',
                                }
                            }) 
                        }}
                    >
                        Sign Out
                    </Button>
                </div>
            </div>
            <div 
                style={{
                    backgroundColor:'#282c34',
                    width: '100%',
                    height: '100vh',
                    display: 'flex',
                    flexDirection: 'column',
                    /* align-items: center;
                    justify-content: center; */
                }}
            >
                <p 
                    style={{ 
                        position: 'absolute', 
                        top: 135, 
                        left: 25, 
                        color: 'rgba(0, 158, 129, 1)', 
                        fontSize: 18, 
                        fontWeight: '600', 
                        textAlign: 'left',
                        textShadowColor: 'rgba(255, 255, 255, 1)',
                        textShadowOffset: {width: 10, height: 10},
                        textShadowRadius: 10,  
                        textUnderlineOffset: 10, 
                        textDecorationLine: 'underline', 
                        textDecorationColor: 'rgba(0, 158, 129, 1)'
                    }}
                >
                    Administrator Center
                    <span 
                        style={{ 
                            textAlign: 'left', 
                            fontSize: 16, 
                            fontWeight: '500', 
                            color: 'rgba(255, 255, 255, 1)', 
                        }}
                    >
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{'TBP Internal Management Platform'}
                    </span>
                </p>

                <React.Fragment>
                    <ul
                        style={{
                            listStyleType: 'none',
                            padding: 0,
                            position: 'relative', 
                            paddingTop: 200, 
                            left: 25, 
                            width: '75vw',
                            // height: 500,
                            // borderStyle: 'solid',
                            borderRadius: 3,
                            backgroundColor: 'rgba(0, 158, 129, 0)',
                            borderColor: 'rgba(0, 158, 129, 1)',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <p 
                            style={{ 
                                // position: 'absolute', 
                                top: 5, 
                                color: 'rgba(255, 255, 255, 1)', 
                                fontSize: 17, 
                                fontWeight: '600', 
                                textAlign: 'left',
                                textShadowColor: 'rgba(255, 255, 255, 1)',
                                textShadowOffset: {width: 10, height: 10},
                                textShadowRadius: 10,  
                            }}
                        >
                            Administrator Center Home
                        </p>

                        {[
                            {
                                destination: '/vr_allpendingapps',
                                buttonTitle: 'Pending VR Applications',
                                buttonSubTitle1: "Review Members' Verified Registry Document Submissions",
                                buttonSubTitle2: "Grant Member Approval & Acceptance to Verified Registry",
                            },
                            {
                                destination: '/collateraldisb',
                                buttonTitle: 'Collateral Disbursements',
                                buttonSubTitle1: "Review Verified Registry Requests for Collateral Assistance",
                                buttonSubTitle2: "Disburse Requested Collateral to Credit-Lines managed by Verified Members",
                            },
                            {
                                destination: '/wallets',
                                buttonTitle: 'Wallet Balances',
                                buttonSubTitle1: "View the Balances of Key Operational Lenderblock Virtual Wallets",
                                buttonSubTitle2: "Check xDAI Balances of Important Corporate Virtual Wallets",
                            },
                            {
                                destination: '/user_lookup',
                                buttonTitle: 'User Lookup',
                                buttonSubTitle1: "Query Usernames to Verify Data of Lenderblock Platforms Members",
                                buttonSubTitle2: "Verify Member Statuses, Upload Documents on behalf of Members",
                            },
                            {
                                destination: '/vr_allmemberapps',
                                buttonTitle: 'All Active VR Applicants',
                                buttonSubTitle1: "Review Members' Verified Registry Document Submissions",
                                buttonSubTitle2: "View Notifications from Users and Correspond with Members",
                            }
                        ].map(listitem => (

                            <Button
                                key={listitem.destination}
                                className='green-clear'
                                style={{
                                    width: '15vw',
                                    marginRight: 25,
                                    float: 'left',
                                    padding: 5, 
                                    marginTop: 20,
                                }}
                                href={listitem.destination}
                            >
                                <div>
                                    <p 
                                        style={{ 
                                            textAlign: 'left',
                                            fontSize: 14, 
                                            fontWeight: '500', 
                                            color: 'rgba(255, 255, 255, 1)', 
                                            marginTop: 10,
                                            marginLeft: 5,
                                        }}
                                    ><b>
                                        {`${listitem.buttonTitle.toUpperCase()}`}
                                    </b></p>
                                    <p 
                                        style={{ 
                                            textAlign: 'left',
                                            fontSize: 12, 
                                            fontWeight: '500', 
                                            color: 'rgba(255, 255, 255, 1)', 
                                            marginTop: 25,
                                            marginLeft: 5,
                                            // textUnderlineOffset: 3, 
                                            // textDecorationLine: 'underline', 
                                            // textDecorationColor: 'rgba(255, 255, 255, 1)',
                                        }}
                                    >
                                        {listitem.buttonSubTitle1}
                                    </p>
                                    <p 
                                        style={{ 
                                            textAlign: 'left',
                                            fontSize: 12, 
                                            fontWeight: '500', 
                                            color: 'rgba(255, 255, 255, 1)', 
                                            marginTop: 15,
                                            marginLeft: 5,
                                            // textUnderlineOffset: 3, 
                                            // textDecorationLine: 'underline', 
                                            // textDecorationColor: 'rgba(255, 255, 255, 1)',
                                        }}
                                    >
                                        {listitem.buttonSubTitle2}
                                    </p>
                                </div>
                            </Button>
                        ))}
                    </ul>
                </React.Fragment>

            </div>
        
        </div>
        );
    }

}

export default Home;
