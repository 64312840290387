import Amplify, { Auth, Storage, API } from 'aws-amplify'
import { Navigate, Routes, Route } from "react-router-dom";
import 'semantic-ui-css/semantic.min.css'
import Login from "./pages/Login"
import Home from "./pages/Home"
import CollateralAssistance from "./pages/CollateralAssistance"
import IC_AllPendingApplications from "./pages/IC_AllPendingApplications"
import IC_ApplicationShow from "./pages/IC_ApplicationShow"
import IC_PendingFileDocumentViewer from "./pages/IC_PendingFileDocumentViewer"
import IC_PostAcceptanceFileDocumentViewer from "./pages/IC_PostAcceptanceFileDocumentViewer"
import VR_AllPendingApplications from "./pages/VR_AllPendingApplications"
import VR_ApplicationShow from "./pages/VR_ApplicationShow"
import VR_PendingFileDocumentViewer from "./pages/VR_PendingFileDocumentViewer"
import VR_PostAcceptanceFileDocumentViewer from "./pages/VR_PostAcceptanceFileDocumentViewer"
import WalletBalances from "./pages/WalletBalances"
import UserLookup from "./pages/UserLookup"
import VR_AllMemberApplications from './pages/VR_AllMemberApplications.js';




import React, {Component, Fragment} from 'react';
import navigationHandler from './utilities/navigator.js';

function App (){

  return(
    <Routes>
      <Route path="" element={<Login />} />
      <Route path="/" element={<Login />} />
      <Route path="/login" element={<Login />} />
      <Route path="/home" element={<Home props={Home.routeProps}/>} />
      <Route path="/collateraldisb" element={<CollateralAssistance props={CollateralAssistance.routeProps}/>} />
      <Route path="/ic_allpendingapps" element={<IC_AllPendingApplications props={IC_AllPendingApplications.routeProps}/>} />
      <Route path="/ic_appshow" element={<IC_ApplicationShow props={IC_ApplicationShow.routeProps}/>} />
      <Route path="/ic_pendingfileviewer" element={<IC_PendingFileDocumentViewer props={IC_PendingFileDocumentViewer.routeProps}/>} />
      <Route path="/ic_postfileviewer" element={<IC_PostAcceptanceFileDocumentViewer props={IC_PostAcceptanceFileDocumentViewer.routeProps}/>} />
      <Route path="/vr_allpendingapps" element={<VR_AllPendingApplications props={VR_AllPendingApplications.routeProps}/>} />
      <Route path="/vr_appshow/:username" element={<VR_ApplicationShow props={VR_ApplicationShow.routeProps}/>} />
      <Route path="/vr_pendingfileviewer/:username" element={<VR_PendingFileDocumentViewer props={VR_PendingFileDocumentViewer.routeProps}/>} />
      <Route path="/vr_postfileviewer/:username" element={<VR_PostAcceptanceFileDocumentViewer props={VR_PostAcceptanceFileDocumentViewer.routeProps}/>} />
      <Route path="/wallets" element={<WalletBalances props={WalletBalances.routeProps}/>} />
      <Route path="/user_lookup" element={<UserLookup props={UserLookup.routeProps}/>} />
      <Route path='/vr_allmemberapps' element={<VR_AllMemberApplications/>} />
    </Routes>
  )
}
export default App;
