import { Navigate } from "react-router-dom";

var routeProps

function navigationHandler(navigationObject) {
    if(navigationObject){
   
        const navigationRoute = navigationObject.navigationRoute 
        const routeParams = navigationObject.routeProps 

        if(routeParams){
            routeProps = routeParams

            localStorage.setItem('applicantUsername', routeParams.applicantUsername)
        }

        switch(navigationRoute){
            case "/home":
                return <Navigate to='/home'/>
            case "/collateraldisb":
                return <Navigate to='/collateraldisb'/>
            case "/ic_allpendingapps":
                return <Navigate to='/ic_allpendingapps'/>
            case "/ic_appshow":
                return <Navigate to='/ic_appshow'/>
            case "/ic_pendingfileviewer":
                return <Navigate to='/ic_pendingfileviewer'/>
            case "/ic_postfileviewer":
                return <Navigate to='/ic_postfileviewer'/>
            case "/vr_allpendingapps":
                return <Navigate to='/vr_allpendingapps'/>
            case "/vr_appshow":
                return <Navigate to='/vr_appshow'/>
            case "/vr_pendingfileviewer":
                return <Navigate to='/vr_pendingfileviewer'/>
            case "/vr_postfileviewer":
                return <Navigate to='/vr_postfileviewer'/>
            case "/wallets":
                return <Navigate to='/wallets'/>
            case "/user_lookup":
                return <Navigate to='/user_lookup'/>
            case "/login":
                return <Navigate to='/login'/>
            case "/":
                return <Navigate to='/login'/>
            default:
                return false
        }

    } else {
        return false
    }
}

export { navigationHandler, routeProps }
